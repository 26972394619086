@import "~bootswatch/dist/superhero/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/superhero/bootswatch";

:root{
  --theme-color: #0f2537;
  --theme-color-secondary: #193e5d;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--theme-color) !important;
}

.card,
.dropdown-menu,
.modal-content {
  background-color: var(--theme-color-secondary) !important;
}

.navbar > .container {
  border-bottom: 1px solid var(--theme-color-secondary)!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container-height-100-vh {
  height: calc(100vh - 90px);
}

.max-w-600px {
  max-width: 600px;
}

.deco-none {
  text-decoration: none;
  color: inherit;
}

.Toastify__toast-theme--dark {
  font-size: 12px;
}

.Toastify__toast-icon {
  width: 20px;
}

img,
svg {
  vertical-align: baseline;
}

.arrow-back {
  vertical-align: middle !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.remove-btn-css {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.grayout {
  opacity: 0.3; /* Real browsers */
  filter: alpha(opacity = 100); /* MSIE */
}

.score-bubble {
  font-size: 15px;
  font-weight: 500;
  margin-right: 15px;
}

.clickable {
  cursor: pointer;
}

.btn-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.p-0-important {
  padding: 0 !important;
}

.white-circle {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}