.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: orangered !important;
}

.nav-tabs {
    color: orangered !important;
}

.nav-tabs .nav-link:hover{
    color:gray
}

.red-background {
    background-color: 	rgb(255,0,0, 0.3) ;
}